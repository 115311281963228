import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={require('../src/img/logo.png')} className='logo'/>
    <a className='logotext'>aahaarsetu</a>
    </div>
  );
}

export default App;
